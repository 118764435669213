// Check for the saved theme preference, if any, in localStorage
var savedTheme = localStorage.getItem('theme');

// If there's no saved theme preference, check for system preference
if (!savedTheme) {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        savedTheme = 'dark-theme';
    } else {
        savedTheme = 'light-theme';
    }
    // Save the default theme preference
    localStorage.setItem('theme', savedTheme);
}

// Set the theme attribute in the HTML tag and apply the theme
document.documentElement.setAttribute('data-theme', savedTheme);
if (savedTheme) {
    document.body.classList.add(savedTheme);
}

function showContent() {
    document.body.style.visibility = 'visible';
    document.body.style.opacity = '1';
}

// Attach click event listener to 'moon-icon' element
document.getElementById('moon-icon').addEventListener('click', function() {
    // Toggle theme
    document.body.classList.toggle('dark-theme');

    // Save theme preference
    var currentTheme = document.body.classList.contains('dark-theme') ? 'dark-theme' : 'light-theme';
    localStorage.setItem('theme', currentTheme);

    // Update theme attribute in HTML tag
    document.documentElement.setAttribute('data-theme', currentTheme);
});

showContent();